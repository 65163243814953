import { API } from "aws-amplify";
import store from "../store";

export const getMembers = () => API.graphql({
  query:
    `query memberList {
      listMemberApplications {
        items {
          id
          createdAt
          completedAt
          IKEACustomerId
          businessAddress
          businessContact
          businessEmail
          businessMisc
          businessMobile
          businessName
          businessNumber
          businessPhone
          businessPlace
          businessPostal
          daycareAddress
          daycareName
          daycarePlace
          daycarePostal
          email
          invoiceAddress
          invoiceEmail
          numberOfChildren
          type
          incomplete
          preschoolAddress
          preschoolName
          preschoolPlace
          preschoolPostal
        }
      }
    }`,
  authMode: "AMAZON_COGNITO_USER_POOLS",
});

export const getUpdates = (callback) => API.graphql({
  query:
    `subscription applications {
      onMemberApplicationChange {
        id
        createdAt
        completedAt
        IKEACustomerId
        businessAddress
        businessContact
        businessEmail
        businessMisc
        businessMobile
        businessName
        businessNumber
        businessPhone
        businessPlace
        businessPostal
        daycareAddress
        daycareName
        daycarePlace
        daycarePostal
        email
        incomplete
        invoiceAddress
        invoiceEmail
        numberOfChildren
        type
        preschoolAddress
        preschoolName
        preschoolPlace
        preschoolPostal
      }
    }`,
  authMode: "AMAZON_COGNITO_USER_POOLS",
  // @ts-ignore
}).subscribe({
  next: (result) => callback(result),
  error: (error) => console.warn(error)
});

export const getMember = (id) => API.graphql({
  query:
    `query member {
      getMemberApplication(id: "${id}") {
        id
        createdAt
        completedAt
        IKEACustomerId
        businessAddress
        businessContact
        businessEmail
        businessMisc
        businessMobile
        businessName
        businessNumber
        businessPhone
        businessPlace
        businessPostal
        daycareAddress
        daycareName
        daycarePlace
        daycarePostal
        email
        invoiceAddress
        invoiceEmail
        numberOfChildren
        type
        preschoolAddress
        preschoolName
        preschoolPlace
        preschoolPostal
        ${store.getRole() === "admins" ? "notes" : ""}
      }
    }`,
  authMode: "AMAZON_COGNITO_USER_POOLS",
}).then(result => result["data"]["getMemberApplication"]);

export const deleteMember = (id) => API.graphql({
  query:
    `mutation deleteMember {
      deleteMemberApplication(id: "${id}") {
        id
      }
    }`,
  authMode: "AMAZON_COGNITO_USER_POOLS",
});

export const updateMember = (id, input) => API.graphql({
  query:
    `mutation updateMember($id: ID!, $input: UpdateMemberApplicationInput!) {
      updateMemberApplication(id: $id, input: $input) {
        id
      }
    }`,
    variables: {
      id,
      input
    },
  authMode: "AMAZON_COGNITO_USER_POOLS",
});
    
// export const sendReminder = (id) => API.graphql({
//   query:
//     `mutation sendReminder {
//       sendCompletionReminder(id: "${id}") {
//         id
//         numberOfRemindersSent
//         lastReminderSentAt
//         reminderWasSent
//       }
//     }`,
//   authMode: "AMAZON_COGNITO_USER_POOLS",
// });