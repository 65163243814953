<template>
  <v-app>
    <v-main>
      <v-container v-if="!isLoginPage()" fluid>
        <router-view />
      </v-container>
      <router-view v-else />
    </v-main>
    <v-footer color="primary" app>
      <span class="white--text"
        >Cmart Support medlemslista v{{ appVersion }}</span
      >
    </v-footer>
  </v-app>
</template>

<script>
import pkg from "../package.json";

export default {
  name: "App",
  data: () => ({
    children: [],
    appVersion: pkg.version
  }),
  methods: {
    isLoginPage() {
      return this.$router.currentRoute.matched.some(
        record => record.path === "/login"
      );
    }
  }
};
</script>
