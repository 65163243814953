<template>
  <div>
    <TheDrawer heading="Medlemmar" current-page="cmart-members" />
    <v-container fluid class="mt-n8 ml-n3">
      <v-row justify="end" class="mb-n4">
        <v-col cols="12">
          <v-text-field
            v-model="searchQuery"
            append-icon="mdi-magnify"
            label="Sök"
            class="mb-10"
            single-line
            hide-details />
        </v-col>
      </v-row>
      <v-row align="start">
        <v-col cols="12">
          <v-data-table
            :headers="preschoolHeaders"
            :items="members.preschool"
            :items-per-page="10"
            :search="searchQuery"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40] }"
            locale="sv"
            sort-by="completedAt"
            sort-desc
            class="elevation-1"
            @click:row="$router.push('/members/' + $event.id)">
            <template #top>
              <div class="v-card__title">Förskolor</div>
            </template>
            <template #[`item.completedAt`]="{ value }">
              <span>{{ value | date("P HH:mm:ss") }}</span>
            </template>
          </v-data-table>
          <v-data-table
            :headers="daycareHeaders"
            :items="members.daycare"
            :items-per-page="10"
            :search="searchQuery"
            locale="sv"
            sort-by="completedAt"
            sort-desc
            class="elevation-1 mb-10"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40] }"
            @click:row="$router.push('/members/' + $event.id)">
            <template #top>
              <div class="v-card__title">Familjedaghem</div>
            </template>
            <template #[`item.completedAt`]="{ value }">
              <span>{{ value | date("P HH:mm:ss") }}</span>
            </template>
          </v-data-table>
          <v-btn
            fab
            dark
            large
            fixed
            bottom
            right
            color="accent"
            title="Exportera till Excel"
            @click="exportToSpreadsheet">
            <v-icon large>mdi-file-excel</v-icon>
          </v-btn>
          <v-data-table
            :headers="incompleteHeaders"
            :items="members.incomplete"
            :items-per-page="10"
            :search="searchQuery"
            locale="sv"
            sort-by="createdAt"
            sort-desc
            v-if="isAdmin"
            @click:row="$router.push('/members/' + $event.id + '?incomplete=1')"
            class="elevation-1 mb-10"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 40] }">
            <template #top>
              <div class="v-card__title">Ej fullvärdiga</div>
            </template>
            <template #[`item.createdAt`]="{ value }">
              <span>{{ value | date("P HH:mm:ss") }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="$route.meta.showDialog" persistent eager width="800">
      <router-view />
    </v-dialog>
  </div>
</template>

<script>
import TheDrawer from "../components/TheDrawer.vue";
import { getMembers, getUpdates } from "../graphql/queries";
import store from "../store";
import { utils, writeFileXLSX } from "xlsx";

export default {
  name: "Members",
  components: {
    TheDrawer
  },
  data: () => ({
    daycareHeaders: [
      { text: "Namn", value: "daycareName" },
      { text: "Ort", value: "daycarePlace" },
      { text: "Antal barn", value: "numberOfChildren" },
      { text: "Datum", value: "completedAt" }
    ],
    preschoolHeaders: [
      { text: "Namn", value: "businessName" },
      { text: "Ort", value: "businessPlace" },
      { text: "Antal barn", value: "numberOfChildren" },
      { text: "Datum", value: "completedAt" }
    ],
    incompleteHeaders: [
      { text: "Namn", value: "preschoolName" },
      { text: "E-post", value: "email" },
      { text: "Datum", value: "createdAt" }
    ],
    members: {
      daycare: [],
      preschool: [],
      incomplete: []
    },
    isAdmin: false,
    searchQuery: "",
  }),
  mounted() {
    this.updateMemberList();
    getUpdates(() => {
      this.updateMemberList();
    });
    this.maybeAddIncompleteMembers();
  },
  methods: {
    updateMemberList() {
      getMembers().then(result => {
        this.members["incomplete"] = [];
        this.members["daycare"] = [];
        this.members["preschool"] = [];
        result.data["listMemberApplications"].items.forEach(member => {
          if (member.incomplete) this.members["incomplete"].push(member);
          else if (member.type === "Familjedaghem")
            this.members["daycare"].push(member);
          else if (member.type === "Förskola")
            this.members["preschool"].push(member);
        });
      });
    },
    maybeAddIncompleteMembers() {
      if (store.getRole() === "admins") {
        this.isAdmin = true;
        const column = {
          text: "IKEA-kundnr",
          value: "IKEACustomerId"
        };
        this.daycareHeaders.push(column);
        this.preschoolHeaders.push(column);
        console.log("loading incomplete members");
      }
    },
    exportToSpreadsheet() {
      const daycareMembers = this.members["daycare"].map(row => ({
        "Verksamhetens/Bolagets juridiska namn": row.businessName,
        Organisationsnummer: row.businessNumber,
        "Bolagets adress": row.preschoolAddress,
        "Bolagets postnummer": row.preschoolPostal,
        "Bolagets postort": row.preschoolPlace,
        "Bolagets telefonnummer": row.businessPhone,
        "Bolagets e-postadress": row.businessEmail,
        Kontaktperson: row.businessContact,
        "E-postadress": row.email,
        Mobiltelefon: row.businessMobile,
        "Besöksadress (om annan än bolagets adress)": row.daycareAddress,
        Postnummer: row.daycarePostal,
        Postort: row.daycarePlace,
        "Leveransadress (om annan än bolagets adress)": row.preschoolAddress,
        Postnummer: row.preschoolPostal,
        Postort: row.preschoolPlace,
        Fakturaadress: row.invoiceAddress,
        "E-postadress för fakturautskick": row.invoiceEmail,
        "GDPR Överenskommelse": "Godkänd"
      }));
      const preschoolMembers = this.members["preschool"].map(row => ({
        "Verksamhetens/Bolagets juridiska namn": row.businessName,
        Organisationsnummer: row.businessNumber,
        "Bolagets adress": row.businessAddress,
        "Bolagets postnummer": row.businessPostal,
        "Bolagets postort": row.businessPlace,
        "Bolagets telefonnummer": row.businessPhone,
        "Bolagets e-postadress": row.businessEmail,
        Kontaktperson: row.businessContact,
        "E-postadress": row.email,
        Mobiltelefon: row.businessMobile,
        "Besöksadress (om annan än bolagets adress)": row.daycareAddress,
        Postnummer: row.daycarePostal,
        Postort: row.daycarePlace,
        "Leveransadress (om annan än bolagets adress)": row.preschoolAddress,
        Postnummer: row.preschoolPostal,
        Postort: row.preschoolPlace,
        Fakturaadress: row.invoiceAddress,
        "E-postadress för fakturautskick": row.invoiceEmail,
        "GDPR Överenskommelse": "Godkänd"
      }));
      const worksheet = utils.json_to_sheet([
        ...preschoolMembers,
        ...daycareMembers
      ]);
      worksheet["!cols"] = [
        { wch: 45 }, 
        { wch: 15 }, 
        { wch: 35 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 45 },
        { wch: 35 },
        { wch: 45 },
        { wch: 15 },
        { wch: 45 },
        { wch: 15 },
        { wch: 15 },
        { wch: 45 },
        { wch: 45 },
        { wch: 45 },
        { wch: 10 },
      ];
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Medlemslista");
      writeFileXLSX(workbook, "medlemslista.xlsx");
    }
  }
};
</script>
