<template>
  <v-container fluid class="primary lighten-4 fill-height">
    <v-row class="d-flex flex-column" align="center">
      <v-col cols="3" align-self="center">
        <v-img src="../../assets/cs_logo.png" contain max-height="50" />
      </v-col>
      <v-col cols="5">
        <p class="text-center display-1">Medlemslista</p>
      </v-col>
      <v-col cols="6">
        <v-card min-width="500" elevation="12">
          <v-card-title class="justify-center mb-n2">Logga in</v-card-title>

          <v-form ref="form" :value="valid">
            <v-card-text class="mb-n8 px-16">
              <v-text-field
                prepend-inner-icon="mdi-at"
                outlined
                v-model="username"
                :disabled="loginInProgress"
                label="E-postadress"
                :rules="required"
                @keydown="errorMessage = ''" />
              <v-text-field
                outlined
                prepend-inner-icon="mdi-lock"
                v-model="password"
                :disabled="loginInProgress"
                label="Lösenord"
                type="password"
                :rules="required"
                @keydown="errorMessage = ''" />
              <p
                class="text-center red--text subtitle-1"
                v-html="errorMessage" />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                small
                color="primary"
                elevation="8"
                large
                class="mb-5"
                min-width="200"
                @click="login"
                :disabled="loginInProgress">
                <template v-if="loginInProgress">
                  <v-progress-circular indeterminate color="accent" />
                </template>
                <template v-else>logga in</template>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "../../store";
import { Auth } from "aws-amplify";

export default {
  data: () => ({
    username: "",
    password: "",
    errorMessage: "",
    valid: false,
    loginInProgress: false,
    forgotPasswordDialog: false,
    resetEmailDialog: false,
    required: [v => !!v || "Måste anges."]
  }),
  watch: {
    "$route.hash": {
      handler: function (value) {
        this.forgotPasswordDialog = value === "#forgot-password";
        this.resetEmailDialog = value === "#reset-email";
      },
      immediate: true
    }
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.loginInProgress = true;
        let session, cognitoUser, groups, userRole;
        try {
          cognitoUser = await Auth.signIn(this.username.trim(), this.password);
          if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
            await Auth.completeNewPassword(cognitoUser, this.password);
          }
          session = await Auth.currentSession();
          groups = session.getIdToken().payload["cognito:groups"];
          console.log("groups:", groups);
          if (!groups || groups.length !== 1)
            throw new Error("Groups != 1");
          else if (groups[0] !== "admins" && groups[0] !== "suppliers")
            throw new Error("Invalid group");
        } catch (error) {
          this.handleError(error);
          return;
        }
        userRole = groups[0];
        store.setRole(userRole);
        store.setUser(cognitoUser);
        this.$router.push("/");
      }
    },
    handleError(error) {
      if (error.code === "NotAuthorizedException")
        this.errorMessage = "Felaktigt användarnamn eller lösenord.";
      else if (error.message === "Groups != 1" || error.message === "Invalid group")
        this.errorMessage = "Tekniskt fel, du har inte behörighet att logga in.<br />Kontakta IT-supporten.";
      else
        this.errorMessage =
          "Ett tekniskt fel uppstod, vänta en stund innan du försöker igen.";
      Auth.signOut();
      setTimeout(() => {
        this.loginInProgress = false;
      }, 2000);
      console.log(error);
    }
  }
};
</script>

<style scoped>
#col {
  height: 100vh;
}

.link {
  text-decoration: none;
}
</style>
