<template>
  <div>
    <v-navigation-drawer :value="localShowDrawer" app>
      <template v-for="section in sections">
        <v-divider v-if="section.showDivider" :key="section.name + 'divider'" />
        <v-subheader
          v-if="section.name"
          :key="section.name"
          class="text-uppercase"
          >{{ section.name }}</v-subheader
        >

        <v-list dense nav shaped :key="section.name + 'list'">
          <v-list-item-group value="currentPage" :color="color">
            <template v-for="link in section.links">
              <v-list-item v-if="!link.links" :key="link.key" :to="link.path">
                <v-list-item-action>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ link.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group no-action v-else :value="true" :key="link.key">
                <template #activator>
                  <v-list-item-action>
                    <v-icon>{{ link.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{ link.title }}</v-list-item-title>
                </template>
                <v-list-item
                  v-for="sublink in link.links"
                  :key="sublink.key"
                  :to="sublink.path"
                >
                  <v-list-item-content class="pl-3">
                    <v-list-item-title
                      :class="{ 'font-italic': currentPage === sublink.key }"
                    >
                      {{ sublink.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list-item-group>
        </v-list>
      </template>

      <template #prepend>
        <v-container class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar color="blue lighten-3" size="50">
              CS
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-menu v-if="loggedInUser" bottom left offset-y>
                  <template #activator="{ on }">
                    <a v-on="on" class="black--text">
                      {{ loggedInUser.displayName }}
                    </a>
                  </template>
                </v-menu>
              </v-list-item-title>
              <v-list-item-subtitle @click="doSignOut"
                ><a>Logga ut</a></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-container>
        <v-divider />
      </template>
    </v-navigation-drawer>
    <v-app-bar app :color="color" dark>
      <v-app-bar-nav-icon @click.stop="localShowDrawer = !localShowDrawer" />
      <v-toolbar-title>{{ heading }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-app-bar>
  </div>
</template>

<script>
import store from "../store";
import { Auth } from "aws-amplify";

export default {
  name: "TheDrawer",
  props: {
    heading: {
      type: String,
      required: true
    },
    currentPage: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    },
    showDrawer: {
      type: Boolean,
      default: null
    }
  },
  data: function () {
    return {
      loggedInUser: store.getUser(),
      userRole: null,
      localShowDrawer: this.showDrawer,
      sections: {
        members: {
          name: "Cmart Support",
          links: [
            {
              path: "/",
              title: "Medlemmar",
              icon: "mdi-account-multiple",
              key: "cmart-members"
            }
          ]
        }
      }
    };
  },
  watch: {
    showDrawer() {
      this.localShowDrawer = this.showDrawer;
    }
  },
  methods: {
    doSignOut() {
      Auth.signOut()
        .then(() => {
          store.clear();
          this.$router.push("/login");
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
