<template>
  <div class="grey lighten-3">
    <v-sheet elevation="2" class="pa-1">
      <v-btn icon right @click="$router.go(-1)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div v-if="notFound">
        <p>Medlemmen gick inte att hitta.</p>
        <a @click="$router.go(-1)">Tillbaka till medlemslistan</a>
      </div>
      <div v-else-if="member">
        <h1 class="font-weight-bold headline justify-left mx-4">
          {{ member.daycareName }}
        </h1>
        <div class="row flex-column ma-4">
          <template v-if="incomplete">
            <p class="font-weight-medium text-uppercase">
              Verksamhetens/Bolagets uppgifter
            </p>
            <div class="col pa-0">
              <table border="0" class="mb-5">
                <tr>
                  <td>Verksamhetens juridiska namn:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.preschoolName">{{ member.preschoolName }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.preschoolName }}</td>
                </tr>
                <tr>
                  <td>Kontaktperson:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.businessContact">{{ member.businessContact }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.businessContact }}</td>
                </tr>
                <tr>
                  <td>E-post:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.email">{{ member.email }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.email }}</td>
                </tr>
                <tr>
                  <td>Telefon:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.businessPhone">{{ member.businessPhone }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.businessPhone }}</td>
                </tr>
                <tr>
                  <td>Ansökan inskickad:</td>
                  <td>
                    {{ new Date(member.createdAt) | date("PP HH:mm") }}
                  </td>
                </tr>
                <tr>
                  <td>Ansökan kompletterad:</td>
                  <td>Nej</td>
                </tr>
                <tr>
                  <td>Antal påminnelser skickade:</td>
                  <td>{{ member.numberOfRemindersSent || 0 }}</td>
                </tr>
                <tr>
                  <td>Senaste påminnelsen skickad:</td>
                  <td v-if="member.lastReminderSentAt">
                    {{ new Date(member.lastReminderSentAt) | date("PP HH:mm") }}
                  </td>
                  <td v-else>aldrig</td>
                </tr>
              </table>
              <!-- <v-btn
                left
                color="primary"
                @click="sendReminder"
                :disabled="buttonIsDisabled"
                >skicka påminnelse</v-btn
              >
              <v-progress-circular indeterminate v-if="sendingReminder" />
              <p v-if="reminderSent">Påminnelse skickad!</p> -->
            </div>
          </template>
          <template v-else-if="member.type === 'Familjedaghem'">
            <p class="font-weight-medium text-uppercase">
              Verksamhetens/Bolagets uppgifter
            </p>
            <div class="col pa-0">
              <table border="0" class="mb-5">
                <tr>
                  <td>Verksamhetens namn:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.businessName">{{ member.businessName }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.businessName }}</td>
                </tr>
                <tr>
                  <td>Familjedaghem:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.daycareName">{{ member.daycareName }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.daycareName }}</td>
                </tr>
                <tr>
                  <td>Organisationsnummer:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.businessNumber">{{ member.businessNumber }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.businessNumber }}</td>
                </tr>
              </table>
            </div>
            <p class="font-weight-medium text-uppercase">Leveransadress</p>
            <div class="col pa-0">
              <table border="0" class="mb-5">
                <tr>
                  <td>Adress:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.daycareAddress">{{ member.daycareAddress }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.daycareAddress }}</td>
                </tr>
                <tr>
                  <td>Postnummer:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.daycarePostal">{{ member.daycarePostal }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.daycarePostal }}</td>
                </tr>
                <tr>
                  <td>Ort:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.daycarePlace">{{ member.daycarePlace }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.daycarePlace }}</td>
                </tr>
              </table>
            </div>
            <p class="font-weight-medium text-uppercase">Fakturering</p>
            <div class="col pa-0">
              <table border="0" class="mb-5">
                <tr>
                  <td>Faktureringsadress:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.invoiceAddress">{{ member.invoiceAddress }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.invoiceAddress }}</td>
                </tr>
                <tr>
                  <td>Fakturering e-post:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.invoiceEmail">{{ member.invoiceEmail }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.invoiceEmail }}</td>
                </tr>
              </table>
            </div>
            <p class="font-weight-medium text-uppercase">Övrigt</p>
            <div class="col pa-0">
              <table border="0" class="mb-5">
                <tr>
                  <td>Övrigt:</td>
                  <td v-if="editMode">
                    <v-text-field v-model="member.businessMisc">{{ member.businessMisc }}
                    </v-text-field>
                  </td>
                  <td v-else>{{ member.businessMisc }}</td>
                </tr>
                <tr>
                  <td>Ansökan kompletterad:</td>
                  <td>{{ new Date(member.completedAt) | date }}</td>
                </tr>
              </table>
            </div>
          </template>
          <template v-else>
            <div class="col pa-0">
              <p class="font-weight-medium text-uppercase">
                Verksamhetens/Bolagets uppgifter
              </p>
              <div class="col pa-0">
                <table border="0" class="mb-5">
                  <tr>
                    <td>Organisationsnummer:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessNumber">{{ member.businessNumber }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessNumber }}</td>
                  </tr>
                  <tr>
                    <td>Adress:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessAddress">{{ member.businessAddress }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessAddress }}</td>
                  </tr>
                  <tr>
                    <td>Postnummer:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessPostal">{{ member.businessPostal }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessPostal }}</td>
                  </tr>
                  <tr>
                    <td>Ort:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessPlace">{{ member.businessPlace }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessPlace }}</td>
                  </tr>
                  <tr>
                    <td>Telefon:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessPhone">{{ member.businessPhone }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessPhone }}</td>
                  </tr>
                  <tr>
                    <td>Kontaktperson:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessContact">{{ member.businessContact }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessContact }}</td>
                  </tr>
                  <tr>
                    <td>E-postadress:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessEmail">{{ member.businessEmail }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessEmail }}</td>
                  </tr>
                  <tr>
                    <td>Mobiltelefon:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessMobile">{{ member.businessMobile }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessMobile }}</td>
                  </tr>
                </table>
              </div>
              <p class="font-weight-medium text-uppercase">Besöksadress</p>
              <div class="col pa-0">
                <table border="0" class="mb-5">
                  <tr>
                    <td>Adress:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.preschoolAddress">{{ member.preschoolAddress }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.preschoolAddress }}</td>
                  </tr>
                  <tr>
                    <td>Postnummer:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.preschoolPostal">{{ member.preschoolPostal }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.preschoolPostal }}</td>
                  </tr>
                  <tr>
                    <td>Ort:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.preschoolPlace">{{ member.preschoolPlace }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.preschoolPlace }}</td>
                  </tr>
                </table>
              </div>
              <p class="font-weight-medium text-uppercase">Leveransadress</p>
              <div class="col pa-0">
                <table border="0" class="mb-5">
                  <tr>
                    <td>Adress:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.daycareAddress">{{ member.daycareAddress }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.daycareAddress }}</td>
                  </tr>
                  <tr>
                    <td>Postnummer:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.daycarePostal">{{ member.daycarePostal }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.daycarePostal }}</td>
                  </tr>
                  <tr>
                    <td>Ort:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.daycarePlace">{{ member.daycarePlace }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.daycarePlace }}</td>
                  </tr>
                </table>
              </div>
              <p class="font-weight-medium text-uppercase">Fakturering</p>
              <div class="col pa-0">
                <table border="0" class="mb-5">
                  <tr>
                    <td>Fakturaadress:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.invoiceAddress">{{ member.invoiceAddress }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.invoiceAddress }}</td>
                  </tr>
                  <tr>
                    <td>E-post för fakturautskick:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.invoiceEmail">{{ member.invoiceEmail }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.invoiceEmail }}</td>
                  </tr>
                </table>
              </div>
              <p class="font-weight-medium text-uppercase">Övrigt</p>
              <div class="col pa-0">
                <table border="0" class="mb-5">
                  <tr>
                    <td>Övrigt:</td>
                    <td v-if="editMode">
                      <v-text-field v-model="member.businessMisc">{{ member.businessMisc }}
                      </v-text-field>
                    </td>
                    <td v-else>{{ member.businessMisc }}</td>
                  </tr>
                  <tr>
                    <td>Ansökan kompletterad:</td>
                    <td>{{ new Date(member.completedAt) | date }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </template>
          <template v-if="canChangeMembers()">
            <table border="0" class="mb-5">
              <tr>
                <td>Anteckningar:</td>
                <td v-if="editMode">
                  <v-text-field v-model="member.notes">{{ member.notes }}
                  </v-text-field>
                </td>
                <td v-else>{{ member.notes }}</td>
              </tr>
            </table>
            <div class="col">
              <v-btn class="float-right" color="primary" @click="editMode = true" v-if="!editMode">
                ändra
              </v-btn>
              <v-btn class="float-left" dark color="red" @click="deleteMemberDialog = true"
                v-if="canChangeMembers() && !editMode">
                ta bort
              </v-btn>
              <template v-if="editMode">
                <v-btn class="float-right" color="primary" @click="save" :disabled="savingInProgress">
                  spara
                </v-btn>
                <v-btn class="float-left" @click="$router.go(-1)" :disabled="savingInProgress">
                  avbryt
                </v-btn>
              </template>
            </div>
          </template>
        </div>
      </div>
    </v-sheet>

    <v-dialog v-model="deleteMemberDialog" persistent eager width="400">
      <v-card>
        <v-card-title>Ta bort medlem</v-card-title>
        <v-card-text> Vill du verkligen ta bort medlemmen? </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn :disabled="deletingMember" text @click="deleteMemberDialog = false">avbryt</v-btn>
          <v-btn :disabled="deletingMember" color="red" dark @click="doDeleteMember">
            <template v-if="!deletingMember">ta bort</template>
            <v-progress-circular indeterminate v-else />
          </v-btn>
        </v-card-actions>
        <v-card-subtitle>
          <p v-if="deleteMemberError" class="font-italic red--text">
            Tekniskt fel uppstod, medlemmen gick inte att ta bort.
          </p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TheDrawer from "../components/TheDrawer.vue";
import { deleteMember, getMember, updateMember } from '../graphql/queries';
import store from '../store';

export default {
  name: "Member",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    TheDrawer
  },
  data() {
    return {
      member: null,
      incomplete: this.$route.query.incomplete,
      notFound: false,
      sendingReminder: false,
      reminderSent: false,
      buttonIsDisabled: false,
      deleteMemberDialog: false,
      deletingMember: false,
      deleteMemberError: false,
      editMode: false,
      savingInProgress: false
    };
  },
  created() {
    getMember(this.id).then(member => {
      if (member) {
        this.member = member;
      } else {
        this.notFound = true;
      }
    });
  },
  methods: {
    async sendReminder() {
      this.sendingReminder = true;
      this.buttonIsDisabled = true;
      this.reminderSent = false;
      await updateDoc(doc(db, this.collection, this.id), {
        sendReminder: true
      });
      setTimeout(() => {
        this.buttonIsDisabled = false;
        this.reminderSent = false;
      }, 10000);
      this.sendingReminder = false;
      this.reminderSent = true;
    },
    canChangeMembers() {
      return store.getRole() === "admins";
    },
    save() {
      this.savingInProgress = true;
      let dataToSave = JSON.parse(JSON.stringify(this.member));
      delete dataToSave.id;
      delete dataToSave.completedAt;
      delete dataToSave.createdAt;
      delete dataToSave.type;
      updateMember(this.id, dataToSave).then(() => {
        this.editMode = false;
        this.savingInProgress = false;
      }).catch(error => {
        console.log(error);
        this.savingInProgress = false;
      });
    },
    doDeleteMember() {
      this.deletingMember = true;
      deleteMember(this.id)
        .then(() => {
          this.deleteMemberDialog = false;
          this.deletingMember = false;
          this.$router.go(-1);
        })
        .catch(error => {
          console.log(error);
          this.deletingMember = false;
          this.deleteMemberError = true;
          setTimeout(() => {
            this.deleteMemberError = false;
          }, 3000);
        });
    }
  }
};
</script>
