export default {
  debug: false,
  state: {
    user: null,
    role: null
  },
  setUser(user) {
    if (this.debug) console.log("Store: Updating user to:", user);
    this.state.user = user;
  },
  getUser() {
    if (this.debug) console.log("Store: Getting user:", this.state.user);
    return this.state.user;
  },
  setRole(role) {
    if (this.debug) console.log("Store: Updating user role to:", role);
    this.state.role = role;
  },
  getRole() {
    if (this.debug) console.log("Store: Getting user role:", this.state.role);
    return this.state.role;
  },
  clear() {
    if (this.debug) console.log("Store: Clearing user state.");
    this.state.user = this.state.role = null;
  }
};
