// check this file to make sure the values are correct before starting the app or deplying it
const config = {
  aws_project_region: 'eu-north-1',
  aws_cognito_region: 'eu-north-1', // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: process.env.VUE_APP_USER_POOL_ID, // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: process.env.VUE_APP_USER_POOL_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)

  aws_appsync_region: 'eu-north-1', // (optional) - AWS AppSync region
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_APPSYNC_GRAPHQL_ENDPOINT, // (optional) - AWS AppSync endpoint
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS', // (optional) - Primary AWS AppSync authentication type
};

if (process.env.NODE_ENV === 'development') {
  console.log("config:", config);
  console.log("env:", process.env);
}

export default config;