import Vue from "vue";
import VueRouter from "vue-router";

import Login from "./pages/login/Login.vue";

import store from "./store";

import SupplierMembers from "./pages/Members.vue";
import Member from "./pages/Member.vue";

Vue.use(VueRouter);

const requiresAuth = {
  meta: {
    requiresAuth: true
  }
};

const routes = [
  {
    path: "/login",
    component: Login
  },
  {
    path: "/",
    component: SupplierMembers,
    ...requiresAuth,
    children: [
      {
        path: "members/:id",
        component: Member,
        props: true,
        meta: {
          showDialog: true,
          requiresAuth: true
        }
      }
    ]
  }
];

let router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach(async (to, from, next) => {
  const user = store.getUser();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("auth guard - path requires authentication");
    if (!user) {
      console.log("not logged in, redirecting to login...");
      next("/login");
    } else {
      console.log("user is logged in - allowing");
      next();
    }
  } else if (to.matched.some(record => record.path === "/login")) {
    console.log("auth guard - trying to access /login");
    if (user) {
      console.log("already logged in, redirecting to /...");
      next("/");
    } else {
      console.log("not logged in, allowed");
      next();
    }
  } else next();
});

export default router;
