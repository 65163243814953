import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import AuthPlugin from "./plugins/auth";
import VueTheMask from "vue-the-mask";
import router from "./router";
import store from "./store";
import { format, isDate } from "date-fns";
import { sv } from "date-fns/locale";
import { Auth, Amplify, Hub } from 'aws-amplify';
import awsconfig from './aws-config';

Amplify.configure(awsconfig);

Vue.use(VueTheMask);
// Vue.use(router);

Vue.filter("date", function (value, _format = "PP") {
  return format(new Date(value), _format, { locale: sv });
});

Vue.config.productionTip = false;

let app = null;

Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signIn':
      console.log('user signed in');
      break;
    case 'signUp':
      console.log('user signed up');
      break;
    case 'signOut':
      console.log('user signed out');
      break;
    case 'signIn_failure':
      console.log('user sign in failed');
      break;
    case 'tokenRefresh':
      console.log('token refresh succeeded');
      break;
    case 'tokenRefresh_failure':
      console.log('token refresh failed');
      break;
    case 'autoSignIn':
      console.log('Auto Sign In after Sign Up succeeded');
      break;
    case 'autoSignIn_failure':
      console.log('Auto Sign In after Sign Up failed');
      break;
    case 'configured':
      console.log('the Auth module is configured');
  }
});

Auth.currentAuthenticatedUser().then(user => {
  console.log("user is alredy logged in, setting user");
  store.setUser(user);
  Auth.currentSession().then(session => {
    let role = session.getIdToken().payload['cognito:groups'][0];
    console.log("setting role");
    store.setRole(role);

    Vue.use(AuthPlugin, { role });
    startApp();
  })
}).catch(error => {
  console.log("user is not logged in, clearing store");
  store.clear();
  console.log(error);
  Vue.use(AuthPlugin, { role: null });
  startApp();
});

const startApp = () => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      render: h => h(App)
    }).$mount("#app");
  }
};
